<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <orisis-logo-full />
        </b-link>

        <b-card-title class="mb-1">
           Mot de passe oublié 🔒 ? 
        </b-card-title>
        <b-card-text class="mb-2">
          Saisissez votre adresse email pour recevoir un lien vous permettant de créer un nouveau mot de passe.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="forgotPasswordForm">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
              class="mb-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Adresse e-mail"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="email@domaine.fr"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Recevoir le lien de ré-initialisation
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="'/login'">
            <feather-icon icon="ChevronLeftIcon" /> Retour à la connexion
          </b-link>
        </b-card-text>

      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, configure, localize } from 'vee-validate';
import { required, email } from "@validations";
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
configure({
  generateMessage: localize('fr', {
    messages: {
        url: 'Ce champ doit être une URL',  
        required: 'Ce champ est requis',
        email: 'Ce champ doit être un email',
      
    },
  }),
});
localize('fr')


import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import OrisisLogoFull from "@core/layouts/components/LogoFull.vue";

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    OrisisLogoFull,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      // validation rules
      required,
      email,
    }
  },
  methods:{
    validationForm() {
      var payload = {
        email: this.userEmail,
        returnUrl: '',
      };
      var that = this;

      this.$refs.forgotPasswordForm.validate().then((success) => {
        if (success) {
          this.$store.dispatch('forgotPassword', {payload:payload, that: that})
        }
      });
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
